import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import { Link } from 'gatsby';

import { QueryFragments } from '../GraphQl/queryFragments'; // eslint-disable-line

import { PortableTextBlock } from '../components/sanity/portableTextBlock';

// Hooks, Querys & State
import { useAppState } from '../state/appState';

// Components
import { PageMeta } from '../components/pageMeta';

const News = ({ data: { allPosts } }) => {

  const { toggleHeaderLight, setHideFooter } = useAppState();

  useEffect(() => {
    toggleHeaderLight(false);
  }, [toggleHeaderLight]);

  useEffect(() => {
    setHideFooter(false);
  }, [setHideFooter]);

  return (
    <div className="my-32 md:my-64 px-gutter">
      <div className="mx-auto max-w-textBlock">
        <h1 className="text-3xl md:text-4xl lg:text-5xl mb-20 font-display text-brand">News</h1>
        <div className="grid grid-cols-1 grid-flow-row">
          {allPosts.edges.map(({ node }) => (
            <div className="border-t border-brand py-12">
              <p className="font-display mb-2">{node.date}</p>
              <Link to={`/news/${node.slug.current}`}>
                <h3 className="text-xl lg:text-2xl font-display text-brand hover:underline">{node.title}</h3>
              </Link>
              {node.meta.metaDescription && (
                <p className="mt-2 mb-4">{node.meta.metaDescription}</p>
              )}
              <Link className="btn-branded" to={`/news/${node.slug.current}`}>
                Read More
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default News;

export const pageQuery = graphql`
  query newsQuery {
    allPosts: allSanityPost(sort: { order: DESC, fields: _createdAt }) {
      edges {
        node {
          title
          author
          meta {
            metaDescription
          }
          date: _createdAt(locale: "NZ", formatString: "DD MMMM Y")
          slug {
            current
          }
        }
      }
    }
  }
`;
